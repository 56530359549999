















































































import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";
import PostersModule from "@/store/vuex-modules/posters";
import eventHub from "@/event-hub";
import Spinners from "@/components/utilities/Spinners.vue";

const posterStore = getModule(PostersModule);

@Component({
    components: { Spinners }
})
export default class PosterImageUpload extends Vue {
    isLoading = false;
    submitSucess = false;
    submitError = false;

    get posterImage() {
        return posterStore.posterImage;
    }

    get posterFileName() {
        return posterStore.posterFileName;
    }

    //life cycle
    created() {
        eventHub.$on("poster-image-upload-started", this.handleUpdateStarted);
        eventHub.$on("poster-image-upload-success", this.handleUpdateSuccess);
        eventHub.$on("poster-image-upload-error", this.handleUpdateError);
        eventHub.$on("poster-image-upload-done", this.handleUpdateDone);
    }
    beforeDestroy() {
        this.resetSubmitFlags();

        eventHub.$off("poster-image-upload-started", this.handleUpdateStarted);
        eventHub.$off("poster-image-upload-success", this.handleUpdateSuccess);
        eventHub.$off("poster-image-upload-error", this.handleUpdateError);
        eventHub.$off("poster-image-upload-done", this.handleUpdateDone);
    }

    //methods
    resetFileSelect() {
        posterStore.setPosterImage("");
        posterStore.setPosterImageFileName("");
    }
    resetSubmitFlags() {
        this.isLoading = false;
        this.submitSucess = false;
        this.submitError = false;
    }
    handleCancel() {
        this.resetFileSelect();
        this.$emit("cancel");
    }
    handleSubmit() {
        if (!this.posterFileName) return;
        this.$emit("submit");
    }
    handleUpdateStarted() {
        this.isLoading = true;
    }
    handleUpdateSuccess() {
        this.resetFileSelect();
        this.submitSucess = true;
    }
    handleUpdateError() {
        this.submitError = true;
    }
    handleUpdateDone() {
        this.isLoading = false;
    }
    fileSelect(e: any) {
        const el = e.target;
        const files = el.files;
        let fileName = "";
        let image = "";

        if (files && 0 < files.length) {
            fileName = files[0].name;
            posterStore.setPosterImageFileName(fileName);
        } else {
            this.resetFileSelect();
            return;
        }

        switch (files[0]["type"]) {
            case "image/jpeg":
            case "image/jpg":
            case "image/png":
                image = files[0];
                posterStore.setPosterImage(image);
                break;
            default:
                alert("Sorry that file type is not allowed.");
                this.resetFileSelect();
                break;
        }
    }
}
