














































































































































































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { formatDistance } from "date-fns";

//vuex module imports
import PostersModule from "@/store/vuex-modules/posters";
import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
import scheduleitemVuexModule from "@/store/vuex-modules/scheduleitem";

//component imports
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import PosterCard from "@/components/shared/PosterCard.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import GeneralCommentsComponent from "@/components/shared/GeneralCommentComponent.vue";
import PosterImageUpload from "@/components/sessions/posters/PosterImageUpload.vue";
import UpcomingStreams from "@/components/exhibitor-profile/UpcomingStreams.vue";

//interface imports
import {
    PosterObject,
    AttendeeScheduleSlot,
    RelatedPosterObject
} from "@/types/interfaces";

//vuex store module declarations and initialization
const posterStore = getModule(PostersModule);
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);
const scheduleitemStore = getModule(scheduleitemVuexModule);

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";

@Component({
    components: {
        PosterCard,
        AttendeeCard,
        Spinners,
        GeneralCommentsComponent,
        PosterImageUpload,
        UpcomingStreams
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class PostersDetails extends Vue {
    @Prop(String)
    id!: string;

    @Prop({ default: "poster" })
    type!: string;

    isSessionActive = false;
    isSessionOver = false;
    favoriteError = false;
    sessionLabelInterval = 0;
    timeLabel = "";
    poster: PosterObject = {
        posterId: "",
        title: "",
        description: "",
        startTime: "",
        endTime: ""
    };

    isLoading = true;
    isLoadingFavorites = true; //loading all favorites
    isLoadingFavorite = false; //for updates to favoriting this poster
    localeTimezone = "";

    isPosterModalOpen = false;

    @Watch("id")
    onIdChange() {
        this.initializePoster();
    }

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        this.initializePoster();
    }

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    /**
     * Methods
     */

    showPosterModal() {
        this.isPosterModalOpen = true;
    }

    closePosterModal() {
        this.isPosterModalOpen = false;
        this.initializePoster();
    }
    initializePoster() {
        const postersPromises = [
            this.fetchPoster(),
            attendeeScheduleStore.getFavoritePosters()
        ];

        this.isLoading = true;
        this.isLoadingFavorites = true;

        Promise.allSettled(postersPromises).then(() => {
            this.isLoading = false;
            this.isLoadingFavorites = false;

            this.logPageVisit();
        });
    }

    fetchPoster() {
        window.clearInterval(this.sessionLabelInterval);

        return new Promise<void>((resolve, reject) => {
            posterStore
                .getPoster(this.id)
                .then((response) => {
                    this.poster = response as PosterObject;

                    // check immediately now that data is available
                    this.handleActiveSessionCheck();

                    this.sessionLabelInterval = window.setInterval(() => {
                        // check again every second
                        this.handleActiveSessionCheck();
                    }, 1000);

                    if (this.poster.relatedPosters) {
                        posterStore.getRelatedPosters(
                            this.poster.relatedPosters
                        );
                    }

                    return resolve();
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    handleActiveSessionCheck() {
        const session = this.poster;
        const startDate = this.sessionDateTime;

        this.isSessionActive = sessionHelpSessionIsActive(session);
        this.isSessionOver = sessionHelpSessionIsOver(session);

        this.timeLabel = formatDistance(window.MgServerTime, startDate);
    }

    goToSession() {
        if (!this.isSessionActive) return;
        this.$router.push(`/meeting/POSTER${this.posterDetail.posterId}`);
    }

    handleFavoriteClick() {
        this.isLoadingFavorite = true;
        const payload = {
            type: "posters",
            id: this.id
        };
        let action;

        if (this.isFavorited) {
            action = scheduleitemStore.removeScheduleItem;
        } else {
            action = scheduleitemStore.putSchedule;
        }

        action(payload)
            .then(() => {
                attendeeScheduleStore.getFavoritePosters();
            })
            .catch(() => {
                this.favoriteError = true;
            })
            .finally(() => {
                this.isLoadingFavorite = false;
            });
    }

    resetFavoriteFlags() {
        this.favoriteError = false;
    }

    getRouteDataForCategory(categoryName: string) {
        return {
            name: "PosterGallery",
            query: {
                category: categoryName
            }
        };
    }

    fetchPosterImage() {
        return posterStore.getPosterImageUrl(this.posterApiOptions);
    }

    startPosterImageUpload() {
        this.isLoading = true;

        eventHub.$emit("poster-image-upload-started");

        this.posterImageUpload()
            .then(() => {
                eventHub.$emit("poster-image-upload-success");
            })
            .catch(() => {
                eventHub.$emit("poster-image-upload-error");
            })
            .finally(() => {
                eventHub.$emit("poster-image-upload-done");
                this.isLoading = false;
            });
    }

    async posterImageUpload() {
        const options = {
            ...this.posterApiOptions,
            image: this.posterImageToUpload
        };

        try {
            await posterStore.uploadPosterImage(options);
        } catch (error) {
            throw new Error(error);
        }

        try {
            await this.fetchPosterImage();
        } catch (error) {
            throw new Error("Get Logo failed.");
        }
    }

    routeDetails() {
        this.$router.push({
            name: "ModifyPoster",
            params: {
                id: this.id
            }
        });
    }

    logPageVisit() {
        const uid = this.$store.getters.user.id;
        const username = this.$store.getters.user.username;
        const name = this.$store.getters.user.name;
        const posterName = this.posterDetail?.title;
        const posterId = this.posterDetail?.posterId;

        const logData = {
            type: `PosterView`,
            uid,
            username,
            name,
            posterName,
            posterId
        };

        logStore.appendLogEntry(logData).catch((err) => console.log(err));
    }

    /**
     * Computed
     */
    get userInfo() {
        return this.$store.getters.userInfo;
    }
    get posterDetail() {
        const res = this.poster;
        return res;
    }
    get myPoster() {
        const myId = this.userInfo.id;
        const posterOwners = this.posterDetail.attendeeIds || [];

        return posterOwners.includes(myId);
    }
    get posterImageUrl() {
        const returnVal = this.fetchPosterImage();
        return returnVal.then((res) => res.data);
    }
    get posterApiOptions() {
        const options = { id: this.id };
        return options;
    }
    get myStartTime() {
        const returnValue = this.posterDetail.startTime;

        return returnValue;
    }

    get sessionDateTime() {
        const iso = this.myStartTime;
        return new Date(iso);
    }

    get myfavoritePosters() {
        return attendeeScheduleStore.favoritePosters;
    }

    get isFavorited() {
        let returnValue = false;

        if (Array.isArray(this.myfavoritePosters)) {
            const match = this.myfavoritePosters.find(
                (item: AttendeeScheduleSlot) => {
                    return Boolean(
                        item.scheduleId && item.scheduleId.includes(this.id)
                    );
                }
            );

            returnValue = Boolean(match);
        }

        return returnValue;
    }

    get sessionEarlyAccessMinutes() {
        const session = this.poster;
        const returnValue = sessionHelpEarlyAccessMinutes(session);

        return returnValue;
    }

    get categoryLabel() {
        return this.poster.categories?.length &&
            this.poster.categories.length > 1
            ? this.headerTextWithDefault.categoryPlural
            : this.headerTextWithDefault.categorySingle;
    }

    get headerText() {
        return this.pageSettings.headerText ? this.pageSettings.headerText : {};
    }

    get headerTextWithDefault() {
        // We're using typeof so that an empty string can be supplied to omit the header. If the key is not defined, still fall back to default.
        return {
            description:
                typeof this.headerText.description == "string"
                    ? this.headerText.description
                    : "Poster Description",
            categorySingle:
                typeof this.headerText.categorySingle == "string"
                    ? this.headerText.categorySingle
                    : "Category",
            categoryPlural:
                typeof this.headerText.categoryPlural == "string"
                    ? this.headerText.categoryPlural
                    : "Categories",
            comments:
                typeof this.headerText.comments == "string"
                    ? this.headerText.comments
                    : "Comments",
            related:
                typeof this.headerText.related == "string"
                    ? this.headerText.related
                    : "Related Posters"
        };
    }

    get pageSettings() {
        return this.$store.getters.getPageOptions("posters") || {};
    }

    get posterImage() {
        if (this.posterDetail.imgPath) {
            return this.posterDetail.imgPath;
        } else if (this.posterDetail.image != "") {
            return this.posterDetail.image;
        } else if (this.myPoster) {
            return this.editorFallbacks.image;
        } else {
            return "";
        }
    }
    get posterImageToUpload() {
        return posterStore.posterImage;
    }

    get relatedPosters() {
        return posterStore.relatedPosters ? posterStore.relatedPosters : [];
    }

    get orderedRelatedPosters() {
        let returnArray: Array<RelatedPosterObject> = [];

        if (this.posterDetail && this.posterDetail.relatedPosters) {
            this.posterDetail.relatedPosters.forEach((posterId: string) => {
                const thisPoster = this.relatedPosters.find(
                    (related) => related.posterId === posterId
                );

                if (thisPoster) {
                    returnArray.push(thisPoster);
                }
            });
        } else {
            returnArray = this.relatedPosters;
        }

        return returnArray;
    }

    get relatedPostersLoading() {
        return !posterStore.relatedPostersLoaded;
    }

    get usePresenterQandA() {
        return this.pageSettings.usePresenterQandA ? true : false;
    }

    get editorFallbacks() {
        return {
            image: `${process.env.BASE_URL}bg/generic-poster-placeholder.jpg`,
            description:
                "This is where the description entered on the edit form appears, once you enter one.",
            categories:
                "Categories will appear here when you add them using the edit form.",
            videoUrl: "https://youtu.be/5GPEHSIXdQ0"
        };
    }

    get descriptionWithFallback() {
        return this.posterDetail.description
            ? this.posterDetail.description
            : this.myPoster
            ? this.editorFallbacks.description
            : "";
    }

    get videoUrlWithFallback() {
        return this.posterDetail.video?.url
            ? this.posterDetail.video?.url
            : this.editorFallbacks.videoUrl;
    }

    get hasCategories() {
        return (
            Array.isArray(this.posterDetail.categories) &&
            this.posterDetail.categories.length > 0
        );
    }

    get showPlaceholder() {
        return {
            video:
                this.myPoster &&
                (!this.posterDetail.video || !this.posterDetail.video.url),
            description:
                this.myPoster &&
                Boolean(
                    !this.posterDetail.description ||
                        this.posterDetail.description.trim() == ""
                ),
            categories: this.myPoster && !this.hasCategories,
            image:
                this.myPoster &&
                Boolean(this.posterImage == this.editorFallbacks.image)
        };
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
