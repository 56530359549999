






































import { Component, Vue, Prop } from "vue-property-decorator";
import { AttendeeDataObject } from "@/types/interfaces";

@Component({
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 80);
        }
    },
    components: {}
})
export default class PosterCard extends Vue {
    @Prop({ type: Array, default: () => [] })
    attendees!: [];

    @Prop({ default: "" })
    id!: string;

    @Prop({ default: "" })
    title!: string;

    @Prop({ default: "" })
    imagePath!: string;

    @Prop({ default: "" })
    description!: string;

    @Prop({ default: "" })
    speakerInfoString!: string;

    @Prop({ default: "" })
    imgThumbnail!: string;

    isLoading = true;

    get pageOptions() {
        return this.$store.getters.getPageOptions("posters") || {};
    }

    get posterImage() {
        let returnValue = "";

        if (this.imgThumbnail) {
            returnValue = this.imgThumbnail;
        } else if (this.pageOptions.posterFallBackImage) {
            returnValue = `${process.env.BASE_URL}bg/${this.pageOptions.posterFallBackImage}`;
        }

        return returnValue;
    }

    get sortedAttendee() {
        if (this.attendees.length < 2) {
            return this.attendees;
        } else {
            const toSort: Array<AttendeeDataObject> = this.attendees;
            return toSort.sort(
                (item: AttendeeDataObject, nxt: AttendeeDataObject) => {
                    return item.lastName && nxt.lastName
                        ? item.lastName.localeCompare(nxt.lastName)
                        : 0;
                }
            );
        }
    }

    get routeDetails() {
        return {
            name: "PosterDetails",
            params: {
                id: this.id
            }
        };
    }
}
